<template>
  <v-window v-model="step">
    <v-window-item>
      <v-row class="py-8">
        <v-col>
          <v-form ref="form">
            <v-row justify="center" align="center" class="flex-column text-center">
              <v-col>
                <h1 class="headline">
                  {{ $t("resetPasswordPage.title") }}
                </h1>
              </v-col>
              <v-col cols="3">
                <p>
                  {{ $t("resetPasswordPage.desc") }}
                </p>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  :label="$t('resetPasswordPage.email')"
                  fill
                  outlined
                  class="pt-4"
                  v-model="resetInfo.email"
                  :rules="emailRules"
                  hide-details
                  @keyup.enter="requestPassReset"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-btn
                  large
                  color="black"
                  dark
                  block
                  elevation="0"
                  :loading="isLoading"
                  class="text-capitalize"
                  @click="requestPassReset"
                >
                  {{ $t("resetPasswordPage.submit") }}
                </v-btn>
              </v-col>
              <v-col cols="3">
                <v-alert class="mt-4" v-if="showErrorAlert" type="error">
                  {{ errorMsg }}
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-window-item>
    <v-window-item>
      <v-row class="py-8">
        <v-col>
          <v-form ref="form2">
            <v-row justify="center" align="center" class="flex-column text-center">
              <v-col>
                <h1 class="headline">
                  {{ $t("resetPasswordPage.title") }}
                </h1>
              </v-col>
              <v-col cols="3">
                <p>
                  {{ $t("resetPasswordPage.desc2") }}
                </p>
              </v-col>

              <v-col cols="3">
                <v-text-field
                  :label="$t('resetPasswordPage.code')"
                  fill
                  outlined
                  class="pt-4"
                  v-model="resetInfo.code"
                  :rules="codeRules"
                  hide-details
                  @keyup.enter="resetPassword"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  :label="$t('resetPasswordPage.newPassword')"
                  fill
                  outlined
                  class="pt-4"
                  v-model="resetInfo.password"
                  :rules="passRules"
                  hide-details
                  @keyup.enter="resetPassword"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-btn
                  large
                  color="black"
                  dark
                  block
                  elevation="0"
                  :loading="isLoading"
                  class="text-capitalize"
                  @click="resetPassword"
                >
                  {{ $t("resetPasswordPage.submit") }}
                </v-btn>
              </v-col>
              <v-col cols="3">
                <v-alert class="mt-4" v-if="showErrorAlert" type="error">
                  {{ errorMsg }}
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-window-item>
  </v-window>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      resetInfo: {},
      emailRules: [
        (v) => !!v || this.$t("resetPasswordPage.required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("resetPasswordPage.emailValid"),
      ],

      passRules: [(v) => v.length >= 4 || this.$t("resetPasswordPage.passwordValid")],
      codeRules: [(v) => !!v || this.$t("resetPasswordPage.required")],
      requiredRules: [(v) => !!v || this.$t("resetPasswordPage.required")],
      isLoading: false,
      showErrorAlert: false,
      step: 0,
      errorMsg: "",
    };
  },
  methods: {
    requestPassReset() {
      var isValid = this.$refs.form.validate();

      if (isValid) {
        this.isLoading = true;
        this.$store
          .dispatch("authModule/requestPassReset", {
            body: {
              email: this.resetInfo.email,
            },
          })
          .then(() => {
            this.step++;
          })
          .catch((err) => {
            console.log(err.response);
            if (err.response.status == 404) {
              this.showErrorAlert = true;
              this.errorMsg = this.$t("resetPasswordPage.notFound");
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    resetPassword() {
      var isValid = this.$refs.form2.validate();

      if (isValid) {
        this.isLoading = true;
        this.$store
          .dispatch("authModule/resetPassword", {
            body: this.resetInfo,
          })
          .then(() => {
            this.$router.push("/login");
          })
          .catch((err) => {
            console.log(err.response);
            if (err.response.status == 404) {
              this.showErrorAlert = true;
              this.errorMsg = this.$t("resetPasswordPage.notFound");
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
